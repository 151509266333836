<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.$route.query.type == 'edit' ? $t('cip.plat.app.attendance.title.editHeadTitle') : $t('cip.plat.app.attendance.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>

    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm.sync="dataForm"
      ></form-layout>
    </el-scrollbar>
    <BaiduMap v-model="dataForm" ref="BaiduMap"></BaiduMap>

  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {add, getDetail} from "@/api/system/attendance";
import BaiduMap from "@/views/system/attendance/BaiduMap";


export default {
  name: "attendanceAdd",
  components: {
    FormLayout,
    HeadLayout,
    BaiduMap,
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
            type: "button",
            icon: "",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: "",
          btnOptType: 'cancel',
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.plat.app.attendance.field.code'),
          prop: 'code',
          span: 8,
          maxlength: 50,
          showWordLimit: true,
          readonly: !['add', 'edit'].includes(this.type),
          placeholder: this.$t('cip.plat.app.attendance.field.code'),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.attendance.field.code'),
              trigger: "blur"
            }]
        },
        {
          label: this.$t('cip.plat.app.attendance.field.longitude'),
          prop: 'longitude',
          span: 8,
          type: 'number',
          minRows: -180,
          maxRows: 180,
          placeholder: this.$t('cip.plat.app.attendance.field.longitude'),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.attendance.field.longitude'),
              trigger: "blur"
            }]
        },
        {
          label: this.$t('cip.plat.app.attendance.field.dimension'),
          prop: 'dimension',
          span: 8,
          type: 'number',
          minRows: -90,
          maxRows: 90,
          placeholder: this.$t('cip.plat.app.attendance.field.dimension'),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.attendance.field.dimension'),
              trigger: "blur"
            }]
        },
        {
          label: this.$t('cip.plat.app.attendance.field.clockRange'),
          prop: 'clockRange',
          span: 8,
          type: 'number',
          minRows: 0,
          maxRows: 5000,
          placeholder: this.$t('cip.plat.app.attendance.field.clockRange'),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.attendance.field.clockRange'),
              trigger: "blur"
            }]
        },
        {
          label: this.$t('cip.plat.app.attendance.field.workShiftTime'),
          prop: 'workShiftTime',
          type: 'time',
          format: "HH:mm:ss",
          valueFormat: "HH:mm:ss",
          span: 8,
          placeholder: this.$t('cip.plat.app.attendance.field.workShiftTime'),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.attendance.field.workShiftTime'),
              trigger: "blur"
            }]
        },
        {
          label: this.$t('cip.plat.app.attendance.field.workUnshiftTime'),
          prop: 'workUnshiftTime',
          format: "HH:mm:ss",
          valueFormat: "HH:mm:ss",
          type: 'time',
          span: 8,
          placeholder: this.$t('cip.plat.app.attendance.field.workUnshiftTime'),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.plat.app.attendance.field.workUnshiftTime'),
              trigger: "blur"
            }]
        },
        {
          label: this.$t('cip.plat.app.attendance.field.address'),
          prop: 'address',
          span: 16,
          maxlength: 50,
          showWordLimit: true,
          placeholder: this.$t('cip.plat.app.attendance.field.address'),
        },
        {
          label: this.$t('cip.plat.app.attendance.field.remark'),
          prop: 'remark',
          span: 16,
          maxlength: 200,
          showWordLimit: true,
          placeholder: this.$t('cip.plat.app.attendance.field.remark'),
        },
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (['view', 'edit'].includes(this.type)) {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {

    initData() {
      getDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate(async (valid) => {
        if (valid) {
          this.$loading()
          add({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
              }
            })
            .finally(() => {
              this.$loading().close()
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
