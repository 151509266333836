<template>
  <div class="center">
    <!--列表label-->
    <head-layout :head-title="$t(`cip.desk.workflow.title.taskTodo`)"></head-layout>
    <!--列表查询条件和头部按钮-->
    <grid-head-layout v-model="searchForm" ref="gridHeadLayout" :head-title="$t(`cip.desk.workflow.title.taskTodo`)"
                      :search-columns="searchColumns" @grid-head-search="gridHeadSearch"
                      @grid-head-empty="gridHeadEmpty"></grid-head-layout>
    <grid-layout ref="gridLayOut" :head-title="$t(`cip.desk.workflow.title.indexHeadTodo`)" :table-options="option"
                 :table-data="data" :table-loading="loading" :data-total="page.total" :grid-row-btn="gridRowBtn"
                 :page="page"
                 @grid-row-detail-click="rowEdit" @page-current-change="onLoad" @page-size-change="onLoad"
                 @page-refresh-change="onLoad" @row-detail="rowEdit" ></grid-layout>

    <el-dialog :visible.sync="bpmnVisible" append-to-body destroy-on-close
               :title="$t(`cip.desk.workflow.title.indexHeadVisible`)" width="70%" custom-class="wf-dialog">
      <wf-design ref="bpmn" style="height: 60vh;" :options="bpmnOption"></wf-design>
    </el-dialog>
  </div>
</template>

<script>
import {detail} from "@/api/plugin/workflow/process";
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import {sinomatodo as getList} from "@/api/plugin/workflow/process";
import GridLayout from "@/views/components/layout/grid-layout";
import {formatTime} from "@/util";
import {getDept} from "@/api/system/dept";
import {myWtTicketPage, ticketType} from "@/api/workTicket";


export default {
  components: {HeadLayout, GridLayout},
  data() {
    return {
      form: {},
      searchForm: {},
      query: {},
      loading: false,
      kictectTypeList:[],
      page: {
        pageSize: PAGE_CONSTANT.TWENTY,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        index: true,
        indexLabel: "序号",
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        linklabel: "processDefinitionName",
        column: [
          {
            label: this.$t('cip.desk.workflow.field.nameOfTheProcess'),
            prop: 'taskName',
            align: "left",
            overHidden: true
          },
          {
            label: this.$t('cip.desk.workflow.field.ProcessClassification'),
            type: 'tree',
            align: "center",
            overHidden: true,
            dicData: [
              {name:'流程待办',value:0},
              {name:'任务待办',value:1},
            ],
            props: {
              label: 'name',
              value: 'value'
            },
            prop: "businessType",
          },
          {
            label: '任务来源',
            prop: 'typeName',
            align: "center",
            overHidden: true,
          },
          {
            label: "下发人",
            prop: 'creatorRealName',
            align: "center",
            overHidden: true,
          },
          {
            label: this.$t('cip.desk.workflow.field.ApplicationTime'),
            prop: 'createTime',
            align: "center",
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm',
            overHidden: true,
          },
        ]
      },
      data: [],
      bpmnVisible: false,
      bpmnOption: {},
      timer: '',
      timerLeave: false
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission", "language"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.deployment_add, false),
        viewBtn: this.vaildData(this.permission.deployment_view, false),
        delBtn: this.vaildData(this.permission.deployment_delete, false),
        editBtn: this.vaildData(this.permission.deployment_edit, false)
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    searchColumns() {
      return [
        {
          label: '任务名称',
          prop: "taskName",
          span: 4,
          maxlength: 20,
          minlength: 2,
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "任务名称",
        },

        // {
        //   label: "任务类型",
        //   prop: "taskType",
        //   span: 4,
        //   maxlength: 20,
        //   minlength: 2,
        //   type: "select",
        //   dataType: "string",
        //   props: {
        //     label: "dictValue",
        //     value: "dictKey"
        //   },
        //   dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=task_type",
        //   placeholder: this.$t('cip.cmn.rule.selectWarning') + "任务类型",
        // },
      ]
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      let buttonBtn = [];
      if (this.permission.wf_process_todo_detail) {
        buttonBtn.push(
            {
              label: "详情",
              emit: "row-detail",
              type: "primary",
              icon: "el-icon-plus",
            });
      }
      //新增按钮
      return buttonBtn;
    },
  },
  methods: {
    // 列表查询 add by steve
    gridHeadSearch(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    // 查询重置 add by steve
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      this.onLoad(this.page, searchForm);
    },
    rowEdit(row) {
      if(!row.url)return this.$message.error('跳转地址错误，请检查下地址是否已经配置')
      let path = row.url.split("?")[0];
      let pathArr=["/#/business/fillReport/index","/#/process/external/iframe/detail","/#/business/reportManager/fillReport/index"]
      if (pathArr.includes(path)) {
        window.open(row.url, "_blank");
      } else {
        this.$router.push(row.url);
      }
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    onLoad(page, params = {}) {
      this.loading = true;
      this.getData (page, params)
      clearInterval(this.timer)
      if(!this.timerLeave){
        this.timer = setInterval(()=>{
          this.getData (page, params)
        }, 5000)
      }
    },

    getData (page, params = {}) {
      this.page = page;
      // this.loading = true;
      this.searchForm.taskUser = this.userInfo.user_id;
      this.searchForm.taskRole = this.userInfo.role_id;
      this.searchForm.ownerDeptId = this.userInfo.dept_id;
      getList(this.page.currentPage, this.page.pageSize, Object.assign({ownerName:this.userInfo.account},params, this.searchForm)).then(res => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    }
  },
  // 初始化数据 add by steve
  mounted() {
    this.onLoad(this.page, {});
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timerLeave = true
  },
};
</script>
